@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
    @apply py-2 px-4 rounded;
}

.btn-blue {
    @apply bg-blue-500 text-white;
}

.btn-blue:hover {
    @apply bg-blue-700;
}

.card {
    @apply rounded overflow-hidden shadow-lg;
    @apply flex flex-col justify-center;
}


/* CSS */
.button-23 {
  background-color: #000000;
  border: 1px solid #222222;
  border-radius: 1px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: 'Cormorant Garamond' ;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  outline: none;
  padding: 13px 23px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow .2s,-ms-transform .1s,-webkit-transform .1s,transform .1s;
  user-select: none;
  -webkit-user-select: none;
  width: fit-content;
}

.button-23:focus-visible {
  box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
  transition: box-shadow .2s;
}

.button-23:active {
  background-color: #F7F7F7;
  border-color: #000000;
  transform: scale(.96);
  color: #000000;
}

.button-23:disabled {
  border-color: #DDDDDD;
  color: #DDDDDD;
  cursor: not-allowed;
  opacity: 1;
}

/* Reverse CSS */
.button-23-reverse {
  background-color: #ffffff; /* White background */
  border: 1px solid #dddddd; /* Light gray border */
  border-radius: 1px;
  box-sizing: border-box;
  color: #000000; /* Black text */
  cursor: pointer;
  display: inline-block;
  font-family: 'Cormorant Garamond' ;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  outline: none;
  padding: 13px 23px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow .2s, -ms-transform .1s, -webkit-transform .1s, transform .1s;
  user-select: none;
  -webkit-user-select: none;
  width: fit-content;
}

.button-23-reverse:focus-visible {
  box-shadow: #dddddd 0 0 0 2px, rgba(0, 0, 0, 0.2) 0 0 0 4px;
  transition: box-shadow .2s;
}

.button-23-reverse:hover {
  background-color: #f5f5f5; /* Light gray background */
  border-color: #000000; /* Darker border */
  color: #000000; /* Black text */
}

.button-23-reverse:active {
  background-color: #222222; /* Dark background */
  border-color: #ffffff; /* White border */
  transform: scale(.96);
  color: #ffffff; /* White text */
}

.button-23-reverse:disabled {
  border-color: #bbbbbb;
  color: #bbbbbb;
  cursor: not-allowed;
  opacity: 1;
}


