@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Ephesis';
  src: url('./assets/fonts/Ephesis-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HomemadeApple";
  src: url('./assets/fonts/HomemadeApple-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Tenor Sans";
  src: url('./assets/fonts/TenorSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Cormorant Garamond";
  src: url('./assets/fonts/CormorantGaramond-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Cormorant Garamond";
  src: url('./assets/fonts/CormorantGaramond-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: "Cormorant Garamond";
  src: url('./assets/fonts/CormorantGaramond-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url('./assets/fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}



body {
  margin: 0;
  font-family: 'Didot', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h3{
  font-family: 'Tenor Sans';
}

body {
  font-family: 'Poppins';
}

nav {
  font-family: 'Cormorant Garamond';
  font-weight: bold;
}