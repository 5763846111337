/* src/styles/SizeChart.css */
.size-chart-table td, .size-chart-table th {
    border: 1px solid rgba(173, 173, 173, 0.371);
    padding: 10px;
    text-align: center;
  }
  
  .size-chart-table {
    border-collapse: collapse;
    width: 100%;
  }